.my_dashboard_page {
  background: #0057c2 url("../../../images/main_background.png") center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  padding-bottom: 40px;
}

.rounded {
  border-radius: 2.70em !important;
  background-color: white;
  margin: auto;
  width: 90%;
  padding: 2%;
}

.dashboard_table_container {
    border: 1px solid black;
    border-radius: 1.5em; 
}

.dashboard_table_header {
    background-color: #EBEBEB;
}

.chip_active {
  display: inline-block;
  color: white;
  padding: 0 10px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  border-radius: 25px;
  margin: 5px;
  background-color: #3f659c;
  text-decoration: none;
}

.chip_active:hover {
  display: inline-block;
  color: azure;
  padding: 0 10px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  border-radius: 25px;
  margin: 5px;
  background-color: #5078b1;
  text-decoration: none;
}

.chip_inactive {
  display: inline-block;
  color: white;
  padding: 0 10px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  border-radius: 25px;
  margin: 5px;
  background-color: #df2d2d;
  text-decoration: none;
}

.chip_inactive:hover {
  display: inline-block;
  color: azure;
  padding: 0 10px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  border-radius: 25px;
  margin: 5px;
  background-color: #f14242;
  text-decoration: none;
}

.chip_sold {
  display: inline-block;
  color: white;
  padding: 0 10px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  border-radius: 25px;
  margin: 5px;
  background-color: #339966;
  text-decoration: none;
}

.chip_sold:hover {
  display: inline-block;
  color: azure;
  padding: 0 10px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  border-radius: 25px;
  margin: 5px;
  background-color: #3caf75;
  text-decoration: none;
}

.chip_expired {
  display: inline-block;
  color: white;
  padding: 0 10px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  border-radius: 25px;
  margin: 5px;
  background-color: #a9abad;
  text-decoration: none;
}

.chip_expired:hover {
  display: inline-block;
  color: azure;
  padding: 0 10px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  border-radius: 25px;
  margin: 5px;
  background-color: #bec0c2;
  text-decoration: none;
}

.chip_user_active {
  display: inline-block;
  color: white;
  padding: 0 10px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  border-radius: 25px;
  margin: 5px;
  background-color: #349668;
  text-decoration: none;
}

.chip_user_active:hover {
  display: inline-block;
  color: azure;
  padding: 0 10px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  border-radius: 25px;
  margin: 5px;
  background-color: #40b17c;
  text-decoration: none;
}

.chip_user_inactive {
  display: inline-block;
  color: white;
  padding: 0 10px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  border-radius: 25px;
  margin: 2px;
  background-color: #df2d2d;
  text-decoration: none;
}

.chip_user_inactive:hover {
  display: inline-block;
  color: azure;
  padding: 0 10px;
  height: 32px;
  font-size: 13px;
  line-height: 32px;
  border-radius: 25px;
  margin: 2px;
  background-color: #f14242;
  text-decoration: none;
}