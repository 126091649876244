li { cursor: pointer; }

 /*the container must be positioned relative:*/
.multi_select {
    border-radius: 25px;
    color: white;
    height: 36px;
    padding-left: 15px;
    padding-right: 25px;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(38,47,89,1) 35%, rgba(40,116,170,1) 100%) !important;
    width: 350px;
    --rmsc-main: #4285f4 !important;
    --rmsc-hover:  rgba(0, 0, 0, 0.3) !important; 
    --rmsc-selected: #3f9af5 !important;
    --rmsc-border: 0px !important;
    --rmsc-gray: #aaa;
    --rmsc-bg: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(38,47,89,1) 35%, rgba(40,116,170,1) 100%) !important; 
    --rmsc-p: 10px !important; /* Spacing */
    --rmsc-radius: 4px !important; /* Radius */
    --rmsc-h: 38px !important; /* Height */
}
  
select option {
    margin: 40px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.list-wrapper {
    max-width: 400px;
    margin: 50px auto;
}
  
.list {
    background: #fff;
    border-radius: 2px;
    list-style: none;
    padding: 10px 20px;
}
  
.list_item {
    display: flex;
    margin: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.733);
}
    
.list_item_last_child {
      border-bottom: none;
}

.list_item_image {
    border-radius: 50%;
    margin-right: 20px;
    width: 64px;
    height: 64px;
}
    
.list_item_content, h4, p {
    margin-left: 20px;
    font-size: 18px;
}
  