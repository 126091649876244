.container {
  height: 100vh;
  align-items: center;
  align-content: center;
}

.loginFormContainer {
  width: 100%;
  padding: 8%;
}

.loginFormLegend {
  width: 14rem;
  background: #dee2e6;
}

.errorMessage {
  font-size: 12px;
  letter-spacing: 0.05rem;
  padding-left: 0.25rem;
}

.horizontal-centered {
  margin: 0 auto;
  display: block;
}

.my_login_page {
  background: #0057c2 url("../../images/login_background.png") center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.form-container {
  background-color: aqua;
}

#titleImg {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 50px;
}

.btn_grad {
  background-image: linear-gradient(
    to right,
    #2c3e50 0%,
    #3498db 51%,
    #2c3e50 100%
  );
}

.btn_grad {
  margin: 10px;
  padding: 10px 50px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 25px;
  border: 0px;
  display: block;
}

.btn_grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.form_control_rounded {
  border-radius: 1.50em;
  background-color: #dcdcdc;
  height: 50px;
  text-align:center;
  background: #dcdcdc url('../../images/user_input_icon.png') no-repeat scroll 0px 0px;
  padding-left:30px;
}

.form_control_rounded_password {
  border-radius: 1.50em;
  background-color: #dcdcdc;
  height: 50px;
  text-align:center;
  background: #dcdcdc url('../../images/password_input_icon.png') no-repeat scroll 0px 0px;
  padding-left:30px;
}
