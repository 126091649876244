.my_notifications_page {
  background: #0057c2 url("../../../images/main_background.png") center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 40px;
  overflow: auto;
}

.loader {  
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.navbar-brand active {
  color: red;
}

.btn_grad {background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%)}
.btn_grad {
   margin: 10px;
   padding: 5px 40px;
   text-align: center;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 25px;
   border: 0px;
   display: block;
 }

 .btn_grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

.rounded {
  border-radius: 2.70em !important;
  background-color: white;
  margin: auto;
  width: 90%;
  padding: 2%;
  align-content: center;
  align-items: center;
}

.form_control_rounded {
  border-radius: 1.50em;
  background-color: #dcdcdc;
  padding-left:30px;
}

/*  */

/*the container must be positioned relative:*/
.multi_select {
  border-radius: 25px;
  color: white;
  height: 36px;
  padding-left: 15px;
  padding-right: 25px;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(38,47,89,1) 35%, rgba(40,116,170,1) 100%) !important;
  width: 350px;
  --rmsc-main: #4285f4 !important;
  --rmsc-hover:  rgba(0, 0, 0, 0.3) !important; 
  --rmsc-selected: #3f9af5 !important;
  --rmsc-border: 0px !important;
  --rmsc-gray: #aaa;
  --rmsc-bg: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(38,47,89,1) 35%, rgba(40,116,170,1) 100%) !important; 
  --rmsc-p: 10px !important; /* Spacing */
  --rmsc-radius: 4px !important; /* Radius */
  --rmsc-h: 38px !important; /* Height */
}

select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.form_control_rounded {
  border-radius: 1.50em;
  background-color: #dcdcdc;
  height: 50px;
  text-align:center;
  background: #dcdcdc url('../../../images/user_input_icon.png') no-repeat scroll 0px 0px;
  padding-left:30px;
}

.form_control_interests_rounded {
  border-radius: 1.50em;
  background-color: #dcdcdc;
  height: 50px;
  text-align:center;
  background: #dcdcdc url('../../../images/interest_input_icon.png') no-repeat scroll 0px 0px;
  padding-left:30px;
}

.form_control_email_rounded {
  border-radius: 1.50em;
  background-color: #dcdcdc;
  height: 50px;
  text-align:center;
  background: #dcdcdc url('../../../images/email_input_icon.png') no-repeat scroll 0px 0px;
  padding-left:30px;
}

.form_control_phone_rounded {
  border-radius: 1.50em;
  background-color: #dcdcdc;
  height: 50px;
  text-align:center;
  background: #dcdcdc url('../../../images/phone_input_icon.png') no-repeat scroll 0px 0px;
  padding-left:30px;
}

.form_control_country_rounded {
  border-radius: 1.50em;
  background-color: #dcdcdc;
  height: 50px;
  text-align:center;
  background: #dcdcdc url('../../../images/country_input_icon.png') no-repeat scroll 0px 0px;
  padding-left:30px;
}

.suspend_button {
  background-image: url( '../../../images/inactivate_button.png' );
  background-size: 115px 34px;
  background-color: white;
  border: none;
  height: 34px;  
  width: 115px;
}

.activate_button {
  background-image: url( '../../../images/activate_button.png' );
  background-size: 100px 34px;
  background-color: white;
  border: none;
  height: 34px;  
  width: 100px;
}

.chip_active {
  display: inline-block;
  color: white;
  padding: 0 15px;
  height: 36px;
  font-size: 16px;
  line-height: 36px;
  border-radius: 25px;
  margin: 5px;
  background-color: #3f659c;
  text-decoration: none;
}

.chip_active:hover {
  display: inline-block;
  color: azure;
  padding: 0 15px;
  height: 36px;
  font-size: 16px;
  line-height: 36px;
  border-radius: 25px;
  margin: 5px;
  background-color: #5078b1;
  text-decoration: none;
}