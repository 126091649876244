.product_page {
    background: #0057c2 url("../../../images/main_background.png") center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    padding-bottom: 40px;
  }

  .rounded {
    border-radius: 2.70em !important;
    background-color: white;
    margin: auto;
    width: 90%;
    padding: 2%;
  }

  .form_control_rounded {
    border-radius: 1.50em;
    background-color: #dcdcdc;
    padding-left:30px;
    margin-bottom: 20px;
  }

  .btn_grad {background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%)}
.btn_grad {
   margin: 10px;
   padding: 5px 50px;
   text-align: center;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #eee;
   border-radius: 25px;
   border: 0px;
   display: block;
 }

 .btn_grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

 .image-gallery {
  width: 40%;
  height: auto;
}

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

.suspend_button {
  background-image: url( '../../../images/inactivate_button.png' );
  background-size: 115px 34px;
  background-color: white;
  border: none;
  height: 34px;  
  width: 115px;
}

.activate_button {
  background-image: url( '../../../images/activate_button.png' );
  background-size: 100px 34px;
  background-color: white;
  border: none;
  height: 34px;  
  width: 100px;
}