.my_dashboard_page {
  background: #0057c2 url("../../images/main_background.png") center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.rounded {
  border-radius: 2.70em !important;
  background-color: white;
  margin: auto;
  width: 90%;
  padding: 2%;
}

.dashboard_table_container {
    border: 1px solid black;
    border-radius: 1.5em; 
}

.dashboard_table_header {
    background-color: #EBEBEB;
}
